<script>
	import Logo from '$static/logo.svg';
</script>

<div class="logo-wrapper">
	<div class="logo">
		<Logo />
	</div>
</div>

<style>
	.logo-wrapper {
		display: grid;
		place-items: center;
		background-color: var(--gf-beige-light);
	}

	.logo {
		width: 200px;
	}
</style>
